import html2Canvas from 'html2canvas';
import JsPDF from 'jspdf';

export default function(selector, title) {
  window.pageYoffset = 0;
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
  let htmlDom = document.querySelector(selector);
  html2Canvas(document.querySelector(selector), {
    allowTaint: true,
    taintTest: false,
    useCORS: true,
    width: htmlDom.clientWidth, // dom 鍘熷瀹藉害
    height: htmlDom.clientHeight,
    scrollY: 0,
    scrollX: 20,
    dpi: window.devicePixelRatio * 4,
    scale: 4 // 鎸夋瘮渚嬪鍔犲垎杈ㄧ巼
  }).then(function(canvas) {
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    const pageHeight = contentWidth / 592.28 * 841.89;
    let leftHeight = contentHeight;
    let position = 0;
    const imgWidth = 595.28;
    const imgHeight = 592.28 / contentWidth * contentHeight;
    const pageData = canvas.toDataURL('image/jpeg', 1.0);
    const PDF = new JsPDF('', 'pt', 'a4');
    if (leftHeight < pageHeight) {
      PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
    } else {
      while (leftHeight > 0) {
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= 841.89;
        if (leftHeight > 0) {
          PDF.addPage();
        }
      }
    }
    PDF.save(title + '.pdf');
  });
}
